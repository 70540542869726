<template>
  <div>
    <v-card>
      <v-card-title
        >Próximamente<v-spacer />
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click="gotoView('dashboard')"
        >
          <v-icon left dark>
            mdi-home
          </v-icon>
          Volver al inicio
          <v-icon right dark>
            mdi-home
          </v-icon>
        </v-btn></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
            v-for="value in ['a', 'b', 'c', 'd']"
            :key="value"
          >
            <v-skeleton-loader
              type="article, list-item-three-line, actions"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click="gotoView('dashboard')"
        >
          <v-icon left dark>
            mdi-home
          </v-icon>
          Volver al inicio
          <v-icon right dark>
            mdi-home
          </v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
export default {
  mixins: [baseViewMixin],
};
</script>

<style lang="scss" scoped></style>
